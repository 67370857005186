<template>
	<div class="main-contents code">
		<div class="tit">문의사항 관리</div>
		<div class="search-box">
			<InputComp v-model="srchFilter.srchTxt" placeholder="제목" @keyup.enter="getInquiryMattInfoList('1')" />
			<img class="search_btn" src="/images/admin/search_btn.png" alt="검색버튼" @click="getInquiryMattInfoList('1')"/>
		</div>
		<!-- 문의사항 리스트 -->
		<div class="Board type3">
			<table class="Board_type3 admin">
				<colgroup>
					<col width="4%">
					<col width="60%">
					<col width="12%">
					<col width="12%">
					<col width="12%">
				</colgroup> 
				<thead>
					<tr>
						<th>NO</th>
						<th>제목</th>
						<th>등록일</th>
						<th>답변상태</th>
						<th>답변 담당</th>
					</tr>
				</thead>
				<tbody v-if="inquiryMattList.length > 0">
					<!-- 최대 25건 조회 -->
					<tr v-for="(row, rowIdx) in inquiryMattList" :key="rowIdx" :class="{new:isNew(row)}">
						<td class="score">{{(pageInfo.pageIndex - 1) * pageInfo.pageUnit + (rowIdx + 1)}}</td>
						<td class="score btn_cursor" @click="modify(row.inquiryMattSeq, row.modLoginId)"><div class="new" v-if="isNew(row)">new</div><span style="margin-left: 45px;">{{row.title}} &#9654;</span></td>
						<td class="score">{{row.regDate | date('yyyy.MM.dd')}}</td>
						<td class="score">
							<span v-if="(row.answerCont == null) || (row.answerCont == '')" style="color: red; font-weight: bold;">미답변</span>
							<span v-else>답변</span>
						</td>
						<td class="score">
							<span v-if="(row.answerCont == null) || (row.answerCont == '')">-</span>
							<span v-else>{{row.modLoginId}}</span>
						</td>

					</tr>
				</tbody>
				<tbody v-else>
					<!-- 관련내용 없을 경우 -->
					<tr>
						<td colspan="4" class="none">등록된 문의사항이 없습니다!</td>
					</tr>
				</tbody>
			</table>
		</div>

		<!-- 페이징부분 -->
		<pagingComp :pageInfo="pageInfo" @page-click="goPage"/>
	</div>
</template>


<script>
import pagingComp from '@/components/PagingComp.vue';

export default {
	data(){
		return{
			// 검색조건
			srchFilter : {
				srchTxt : '',
				pageIndex : 1
			},

			// 문의사항 목록
			inquiryMattList : [],
			
			// 페이징
			pageInfo : {}
		}
	},

	components : {
		pagingComp
	},

	mounted(){
		var keys = Object.keys(this.srchFilter);
		for(var key in this.$route.params){
			if(keys.indexOf(key) >= 0){
				this.srchFilter[key] = this.$route.params[key];
			}
		}
		this.getInquiryMattInfoList();
	},

	methods : {
		// 문의사항 목록 조회
		getInquiryMattInfoList(div) {
			var param = this.srchFilter;
			param.pageUnit = '25';
			param.pageSize = '10';

			if(div) param.pageIndex = 1;
			this.$.httpPost('/api/main/adm/cts/getInquiryMattInfoList', param)
				.then(res => {
					this.inquiryMattList = res.data.inquiryMattList;
					this.pageInfo = res.data.pageInfo;
				}).catch(this.$.httpErrorCommon);
		},

		isNew(row){
			return new Date().add(-7, 'day') < this.$format.date(row.regDate, 'date') ? true : false;
		},

		modify(inquiryMattSeq, modLoginId){
			this.$router.push({name: 'MAN939M02', params: {inquiryMattSeq:inquiryMattSeq, modLoginId:modLoginId}});
		},

		// 페이징 이동
		goPage(page){
			this.srchFilter.pageIndex = page;
			this.getInquiryMattInfoList();
		}
	}
}
</script>